import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Blogs from "../components/Blogs/Blogs"

const BlogsPage = ({
  data: { allMdx: { nodes: blogs } }
}) => {
  return (
    <Layout>
      <section>
        <Blogs blogs={blogs} title="All Blogs" />
      </section>
    </Layout>
  )
}

export const query = graphql`
    {
      allMdx(filter: { fileAbsolutePath: { regex: "/content/blogs/" }}, sort: { fields: frontmatter___id }) {
          nodes {
              frontmatter {
                  featured
                  tags {
                      id
                      text
                  }
                  title
                  link
                  image {
                      childImageSharp {
                          fluid {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
          }
      }
    }
`

export default BlogsPage

