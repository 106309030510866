import React from "react"
import { Link } from "gatsby"
import Title from "../Title/Title"
import Blog from "../Blog/Blog"
import "./blogs.scss"

const Blogs = ({ blogs, title, homePage }) => {
  return (
    <>
      <section className="section">
        <Title title={title}></Title>
        <div className="section__center blogs">
          {blogs.map(blg => {
            let blog = blg.frontmatter
            return <Blog key={blog.id} {...blog} />
          })}
        </div>
        <Link
          to={homePage ? '/blogs/' : "/"}
          target="_blank"
          rel="nofollow noopener noreferrer"
          className="btn btn__center"
          style={homePage ? { marginBottom: 0 } : { marginBottom: '5rem' }}
        >
          {homePage ? 'more blogs' : "home"}
        </Link>

      </section>
    </>
  )
}

export default Blogs
