import React from "react"
import Image from "gatsby-image"
import "./Blog.scss"

const Blog = ({ title, image, tags, link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferer">
      <article className="blog">
        <Image
          fluid={image.childImageSharp.fluid}
          className="blog__image"
        />
        <div className="blog__title">
          {title}
        </div>
        <div className="blog__tags">
          {tags.map(item => {
            return (
              <span key={item.id} className="blog__tag">
                #{item.text}
              </span>
            )
          })}
        </div>
      </article>
    </a>
  )
}

export default Blog